import React from 'react';
import PropTypes from 'prop-types';
import { Modal, connectModal, Button } from '@gsa/afp-component-library';

const Title = () => {
  return (
    <h2>
      This is a U.S. General Services Administration Federal Government system
      for authorized use only
    </h2>
  );
};

const Content = () => {
  return (
    <>
      <p>
        This is a U.S. General Services Administration Federal Government computer
        system that is &quot;FOR OFFICIAL USE ONLY&quot;. By using this system you
        acknowledge: this system is subject to monitoring. Unauthorized attempts
        to upload information, view information, or change information and
        attempts to bypass authentication or authorization mechanisms to gain
        access into unauthorized areas of the website and any other actions that
        would compromise the confidentiality, integrity, and availability of this
        system are strictly prohibited and may be punishable under the Computer
        Fraud and Abuse Act of 1986 and the National Information Infrastructure
        Protection Act.
      </p>

      <p>
        This system contains Controlled Unclassified Information (CUI). All individuals viewing,
        reproducing or disposing of this information are required to protect it in accordance with
        32 CFR Part 2002 and GSA Order CIO 2103.2 CUI Policy.
      </p>
    </>
  );
};

const Actions = ({ onConfirm }) => (
  <>
    <Button
      label="I agree"
      variant="primary"
      type="submit"
      form="agreement-confirm-form"
      data-testid="agreement-confirm-btn"
      onClick={onConfirm}
    />
  </>
);

Actions.propTypes = {
  onConfirm: PropTypes.func.isRequired,
};

const AgreementModal = ({
  onConfirm,
  onClose
}) => {
  const handleConfirm = () => {
    if (typeof onConfirm === 'function') {
      onConfirm();
    }
  }
  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  }
  const ConfirmModal = connectModal(() => (
    <Modal
      hideClose
      variant="large"
      title={<Title />}
      onClose={handleClose}
      actions={
        <Actions
          onConfirm={handleConfirm}
        />
      }
    >
      <Content />
    </Modal>
  ));

  return <ConfirmModal isOpen />;
};

AgreementModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default AgreementModal;
