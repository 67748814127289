import React, { useEffect, useState } from 'react';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { FilterPanel } from '@gsa/afp-component-library';
import { useUserFilter } from '../providers';
import AgencyFilterItem from '../organization-filters/agency-filter';
import BureauFilterItem from '../organization-filters/bureau-filter';
import OfficeFilterItem from '../organization-filters/office-filter';
import { Types } from '../constants/user-constants';
import { canManageAll } from '../authorization';

const commonFilterStructure = [
  {
    title: 'First name',
    key: 'first_name',
    id: 'filter-user-first-name',
    type: 'text',
    permanent: false,
    placeholder: 'Enter First name',
    operator: '$like',
  },
  {
    title: 'Last name',
    key: 'last_name',
    id: 'filter-user-last-name',
    type: 'text',
    permanent: false,
    placeholder: 'Enter Last name',
    operator: '$like',
  },
  {
    title: 'Email',
    key: 'email',
    id: 'filter-user-email',
    type: 'text',
    permanent: false,
    placeholder: 'Enter email',
    operator: '$like',
  },
  {
    title: 'Role name',
    key: '$userRoles.id$',
    id: 'filter-user-role-name',
    type: 'select',
    permanent: false,
    operator: '$in',
    options: [{ value: '', label: '- Select -', defaultValue: true }],
    value: '',
  },
  {
    title: 'User status',
    key: 'user_status_id',
    id: 'filter-user-user-statuses',
    type: 'multiselect',
    value: [],
    options: [],
    permanent: false,
    operator: '$in',
    hideClear: true,
  },
];

const filterStructureBy = {
  [Types.CUSTOMER]: [
    ...commonFilterStructure,
    {
      title: 'Organization',
      key: 'organization',
      filters: [
        {
          title: 'Agency',
          key: '$customerAttrs.registered_agency_code$',
          id: 'filter-customer-agency-code',
          component: AgencyFilterItem,
          permanent: false,
          operator: '$exact',
          hideClear: true,
          value: '',
        },
        {
          title: 'Bureau',
          key: '$customerAttrs.registered_bureau_code$',
          id: 'filter-customer-bureau-code',
          component: BureauFilterItem,
          permanent: false,
          operator: '$exact',
          hideClear: true,
        },
        {
          title: 'Office',
          key: '$customerAttrs.registered_office_code$',
          id: 'filter-customer-office-code',
          component: OfficeFilterItem,
          permanent: false,
          operator: '$exact',
          hideClear: true,
        },
      ],
    },
  ],
  [Types.GSA_EMPLOYEE]:[
    ...commonFilterStructure,
    {
      title: 'User type',
      key: 'user_type_id',
      id: 'filter-user-user-types',
      type: 'select',
      permanent: false,
      operator: '$exact',
      options: [{ value: '', label: 'All user type', defaultValue: true }],
      value: '',
    },
    {
      title: 'Organization',
      key: 'organization',
      filters: [
        {
          title: 'Agency',
          key: '$customerAttrs.registered_agency_code$',
          id: 'filter-customer-agency-code',
          component: AgencyFilterItem,
          permanent: false,
          operator: '$exact',
          hideClear: true,
        },
        {
          title: 'Bureau',
          key: '$customerAttrs.registered_bureau_code$',
          id: 'filter-customer-bureau-code',
          component: BureauFilterItem,
          permanent: false,
          operator: '$exact',
          hideClear: true,
        },
        {
          title: 'Office',
          key: '$customerAttrs.registered_office_code$',
          id: 'filter-customer-office-code',
          component: OfficeFilterItem,
          permanent: false,
          operator: '$exact',
          hideClear: true,
        },
      ],
    },
    {
      title: 'Recertification status',
      key: 'recertificationStatus',
      id: 'filter-user-recertification',
      type: 'multiselect',
      value: [],
      options: [],
      permanent: false,
      operator: '$in',
      hideClear: true,
    },
  ],
  all: [
    ...commonFilterStructure,
    {
      title: 'User type',
      key: 'user_type_id',
      id: 'filter-user-user-types',
      type: 'select',
      permanent: false,
      operator: '$exact',
      options: [{ value: '', label: 'All user type', defaultValue: true }],
      value: '',
    },
    {
      title: 'Organization',
      key: 'organization',
      filters: [
        {
          key: '$customerAttrs.registered_agency_code$',
          id: 'filter-customer-agency-code',
          component: AgencyFilterItem,
          permanent: false,
          operator: '$exact',
          hideClear: true,
        },
        {
          key: '$customerAttrs.registered_bureau_code$',
          id: 'filter-customer-bureau-code',
          component: BureauFilterItem,
          permanent: false,
          operator: '$exact',
          hideClear: true,
        },
        {
          key: '$customerAttrs.registered_office_code$',
          id: 'filter-customer-office-code',
          component: OfficeFilterItem,
          permanent: false,
          operator: '$exact',
          hideClear: true,
        },
      ],
    },
    {
      title: 'Recertification status',
      key: 'recertificationStatus',
      id: 'filter-user-recertification',
      type: 'multiselect',
      value: [],
      options: [],
      permanent: false,
      operator: '$in',
      hideClear: true,
    },
  ]
};

const getFilterStructureByUserType = (userTypeId, ability) => {
  if (canManageAll(ability)) {
    return filterStructureBy.all;
  }
  return filterStructureBy[userTypeId] || [...commonFilterStructure];
}

const UserFilter = () => {
  const {
    getUserTypes,
    userTypes,
    getUserStatuses,
    userStatuses,
    getActiveRoles,
    activeRoles,
    getRecertStatuses,
    recertStatuses,
    setFilters,
  } = useUserFilter();

  const ability = useAppAbility();
  const { currentUser } = useCurrentUser();

  const [filterStructure, setfilterStructure] = useState([]);
  const [sort] = useState('name ASC');
  const addOptions = (id, options) => {
    const filterByUserType = getFilterStructureByUserType(currentUser?.userType?.id, ability);
    return filterByUserType.map((f) => {
      const filter = f;
      if (filter?.id === id) {
        if (filter.type === 'multiselect') {
          filter.options = [...options]; // no default option for multiselect
        } else {
          filter.options = [filter.options[0], ...options]; // appends default option first for other filter types
        }
      }
      return filter;
    });
  };

  useEffect(() => {
    getUserTypes();
    getRecertStatuses();
  }, []);

  useEffect(() => {
    setfilterStructure(() => getFilterStructureByUserType(currentUser?.userType?.id, ability));
  }, [currentUser]);

  useEffect(() => {
    getUserStatuses({
      variables: {
        order: sort,
      },
    });
    getActiveRoles({
      variables: {
        order: sort,
      },
    });
  }, [sort]);

  useEffect(() => {
    setfilterStructure(
      addOptions(
        'filter-user-user-statuses',
        userStatuses.map((ut) => ({ value: ut.id, label: ut.name })),
      ),
    );
  }, [userStatuses]);

  useEffect(() => {
    setfilterStructure(
      addOptions(
        'filter-user-user-types',
        userTypes.map((ut) => ({ value: ut.id, label: ut.name })),
      ),
    );
  }, [userTypes]);

  useEffect(() => {
    setfilterStructure(
      addOptions(
        'filter-user-role-name',
        activeRoles.map((ut) => ({ value: ut.id, label: ut.name })),
      ),
    );
  }, [activeRoles]);

  useEffect(() => {
    setfilterStructure(
      addOptions('filter-user-recertification', recertStatuses),
    );
  }, [recertStatuses]);

  return (
    <div data-testid="user-filters">
      {filterStructure.length && 
        <FilterPanel.FilterPanel
          filterStructure={filterStructure}
          setQueryFiltersState={(filters) => {
            setFilters(filters);
          }}
          clearButtonLabel="Reset All"
          showClearIcon
        />}
    </div>
  );
};

export default UserFilter;
