

export const vehicleRedirect = () => {
  const url = [
    window.AFP_CONFIG.appURLs.vms,
    window.location.pathname,
    window.location.search,
  ].join('');
  window.location.assign(url);
  return null;
};

export const catalogRedirect = () => {
  window.location.assign(`${window.AFP_CONFIG.appURLs.catalog}/catalog`);
  return null;
};

export const storeRedirect = () => {
  window.location.assign(`${window.AFP_CONFIG.appURLs.store}/store`);
  return null;
};

export const salesRedirect = () => {
  if (window.AFP_CONFIG && window.AFP_CONFIG.appURLs && window.AFP_CONFIG.appURLs.marketplace) {
    window.location.assign(`${window.AFP_CONFIG.appURLs.marketplace}/sales/landing`);
  }
  return null;
};
