import { useMutation, useLazyQuery } from '@apollo/client';
import {
  Button,
  connectModal,
  Modal,
  RequiredFieldIndicator,
  SelectDropdown,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { UPDATE_USER_STATUS, GET_USER_ROLE_SCOPES_BY_USER_ID, GET_USER_ACTIVE_BIDS, REMOVE_VEHICLE_SALE_BID } from '../../../../services/data-layer';
import {
  Status,
  StatusLabel,
} from '../../../admin-console/users/constants/user-details-constants';
import { canActivateOrDeactivateUser, canUpdateUser, canSetAsDefaulted } from '../../authorization';
import UserStatusTag from '../user-status-tag';
import './styles/modal.css';
import { RequiredFieldsHint } from '../../../../components/Required/RequiredFieldsHint';

export const isEditableStatus = (userStatusId) => {  
  switch (userStatusId) {
    case Status.ACTIVE:
    case Status.INACTIVE:
    case Status.PENDING_REACTIVATION:
    case Status.DEACTIVATED:
    case Status.DENIED:
    case Status.DEFAULTED:
      return true;
    default:
      return false;
  }
};

export const canEditProfile = (ability, userStatusId) => {  
  return canUpdateUser(ability) && isEditableStatus(userStatusId);
};

export const canEditUserStatus = (
  ability, 
  userStatusId, 
  currentUserId, 
  targetUser) => {  
  return currentUserId !== targetUser?.id
    && (canActivateOrDeactivateUser(ability) || canSetAsDefaulted(ability))
    && isEditableStatus(userStatusId);  
};

const Title = () => {
  return <h1 data-testid="edit-user-state-modal-title">Edit account status</h1>;
};

const Actions = ({ onCancel, onConfirm, hideButton }) => {
  return (
    <>
      <Button
        label="Cancel"
        variant={`${hideButton ? 'primary' : 'unstyled'}`}
        className="padding-right-1"
        onClick={onCancel}
        data-testid="edit-user-state-cancel-btn"
      />
      {!hideButton ? (
        <Button
          label="Update user status"
          variant="primary"
          type="submit"
          data-testid="edit-user-state-confirm-btn"
          onClick={onConfirm}
        />
      ) : null}
    </>
  );
};

Actions.defaultProps = {
  hideButton: false,
};

Actions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  hideButton: PropTypes.bool,
};

export const EditUserStatusModal = ({
  isOpen,
  userId,
  userFirstName,
  userLastName,
  userEmail,
  userStatusId,
  userStatusName,
  onDataChanged,
  onClose,
  onFeedback,
}) => {

  const ConfirmModal = connectModal(() => {
    const [newStatusId, setNewStatusId] = useState();
    const [errorRequired, setErrorRequired] = useState(false);
    const [roles, setRoles] = useState(null);
    const statusInputRef = useRef();
    const ability = useAppAbility();
    const hideButton = false;

    const [cancelBid] = useMutation(REMOVE_VEHICLE_SALE_BID, {
      context: {
        clientName: 'marketplace',
      },
    });

    const cancelAllBids = async (bids) => {
      if (!bids || bids.length === 0) {
        return;
      }
  
      try {
        const cancelPromises = bids.map((bid) =>
          cancelBid({
            variables: {
              cancelBidInput: {
                bidId: bid.id,
                bidCancelReason: 'Other',
                bidCancelOtherReason: 'User has been defaulted, Cancelling all active bids',
              },
            },
          })
        );
  
        await Promise.all(cancelPromises);
        onFeedback({
          type: 'success',
          message: (
            <span>
              The user status is successfully updated to {' '}
              <b>defaulted</b> and all bids have been canceled.
            </span>
          ),
        });
        onDataChanged();
        onClose();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error canceling bids', error);
        onFeedback({ type: 'error', message: 'Failed to cancel bids' });
        onClose();
      }
    };

    const [fetchUserActiveBids] = useLazyQuery(GET_USER_ACTIVE_BIDS, {
      context: { clientName: 'marketplace' },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (Array.isArray(data?.userBidHistory?.bids) && data.userBidHistory.bids.length > 0) {
          cancelAllBids(data.userBidHistory.bids);
        } else {
          onFeedback({
            type: 'success',
            message: (
              <span>
                The user status is successfully updated to {' '}
                <b>defaulted</b>.
              </span>
            ),
          });
          onDataChanged();
          onClose();
        }
      },
      onError: () => {
        // eslint-disable-next-line no-console
        console.error('Error fetching bids');
        onFeedback({ type: 'error', message: 'Failed to fetch active bids' });
        onClose();
      },
    });

    const [
      getUserRoleScopes,
    ] = useLazyQuery(GET_USER_ROLE_SCOPES_BY_USER_ID, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: () => {
        setRoles(null);
      },
      onCompleted: (data) => {
        setRoles(data?.getUserRoleScopes?.rows.map(row => row?.role?.name));
      },
    });

    const getRoles = () => {
      getUserRoleScopes({
        variables: {
          userId
        },
      });
    };

    useEffect(() => {
      getRoles();
    }, []);

    const newStatusOptions = [{ value: '', label: '-Select-' }];
    if (userStatusId !== Status.ACTIVE) {
      newStatusOptions.push({
        value: Status.ACTIVE,
        label: StatusLabel.ACTIVE,
      });
    }
    if (userStatusId !== Status.DEACTIVATED) {
      newStatusOptions.push({
        value: Status.DEACTIVATED,
        label: StatusLabel.DEACTIVATED,
      });
    }

    if (userStatusId !== Status.DEFAULTED && canSetAsDefaulted(ability) && roles && roles.includes('Bidder')) {
      newStatusOptions.push({
        value: Status.DEFAULTED,
        label: StatusLabel.DEFAULTED,
      });
    }

    const [updateUserStatus] = useMutation(UPDATE_USER_STATUS, {
      onError: () => {
        onFeedback({ type: 'error' });
        onClose();
      },
      onCompleted: (data) => {
        if (data.updateUserStatus) {
          const getStatus = (statusId) => {
            switch (`${statusId}`) {
              case Status.ACTIVE:
                return 'active';
              case Status.DEACTIVATED:
                return 'deactivated';
              case Status.DEFAULTED:
                return 'defaulted';
              default:
                return 'unknown';
            }
          };

          // Cancel bids if statusId is 9 (DEFAULTED)
          if (getStatus(newStatusId) === 'defaulted') {
            fetchUserActiveBids({
              variables: {
                userBidHistoryInput: {
                  orderBy: [
                    {
                      column: 'bidDate',
                      order: 'DESC',
                    },
                  ],
                  type: 'USER_BIDS',
                  vehicleSaleId: '',
                  bidderUserIds: [userId],
                  filter: {
                    vehicleDetails: { saleStatus: 'Active' },
                  },
                },
              },
            });
          } else {
            // No bid cancellation, just update status
            onFeedback({
              type: 'success',
              message: (
                <span>
                  The user status is successfully updated to {' '}
                  <b>{getStatus(newStatusId)}</b>.
                </span>
              ),
            });
            onDataChanged();
            onClose();
          }
        }
      },
    });

    const validateForm = (status) => {
      setErrorRequired(!status);
      if (!status) {
        statusInputRef?.current?.focus();
      }
    }

    return (
      <Modal
        variant="large"
        title={<Title />}
        actions={
          <Actions
            onCancel={() => {
              onClose();
            }}
            onConfirm={() => {
              validateForm(newStatusId);
              const statusId = parseInt(newStatusId, 10) || 0;
              if (statusId && `${statusId}` !== userStatusId) {
                updateUserStatus({
                  variables: { id: `${userId}`, status: statusId },
                });
              }
            }}
            hideButton={hideButton}
          />
        }
        onClose={() => {
          onClose();
        }}
      >
        <RequiredFieldsHint />

        <h2 className="text-heavy text-primary font-sans-xs text-uppercase">
          User Information
        </h2>
        <span className="section-minor-header">Name</span>
        <div className="padding-bottom-4">
          {userFirstName} {userLastName}
        </div>

        <span className="section-minor-header">Email address</span>
        <div className="padding-bottom-4">
          <a
            href={`mailto:${userEmail}`}
            data-testid="edit-user-status-modal-email"
          >
            {userEmail}
          </a>
        </div>

        <h2 className="text-heavy text-primary font-sans-xs text-uppercase">
          Account Status
        </h2>
        <span>Current account status</span>
        <div className="padding-bottom-4">
          <UserStatusTag value={userStatusName} />
        </div>

        <span>
          New account status <RequiredFieldIndicator />
        </span>
        <br />
        <span>
          Please choose below the new account status for the following user.
        </span>
        <SelectDropdown
          id="newState"
          name="newState"
          onChange={(ev) => {
            validateForm(ev.target.value);
            setNewStatusId(ev.target.value);
          }}
          options={newStatusOptions}
          value={newStatusId}
          data-testid="new-state-select-dropdown"
          inputRef={statusInputRef}
          errorMessage={(errorRequired ? 'Please select account status' : '')}
          aria-invalid={errorRequired} 
        />
      </Modal>
    );
  });

  return <ConfirmModal isOpen={isOpen} />;
};

EditUserStatusModal.propTypes = {
  onDataChanged: PropTypes.func.isRequired,
  onFeedback: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,

  isOpen: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  userFirstName: PropTypes.string.isRequired,
  userLastName: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  userStatusId: PropTypes.string.isRequired,
  userStatusName: PropTypes.string.isRequired,
};

export default EditUserStatusModal;
