import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useCurrentUser, InternalError } from '@gsa/afp-shared-ui-utils';
import { Modal, connectModal, Button } from '@gsa/afp-component-library';
import { ACCEPT_BEHAVIOR_RULES } from '../../services/data-layer';
import PDFViewer from '../pdf/pdf-viewer';

const Title = () => {
  return (
    <h2 data-testid="behavior-rules-heading">
      Annual GSA Information Technology (IT) General Rules of Behavior
    </h2>
  );
};

const Content = () => {
  const fileName = 'CC045655_directive_CIO_21041B_CHGE_2,_GSA_IT_General_Rules_of_Behavior.pdf';
  const source = () => `${process.env.PUBLIC_URL}/${fileName}`;
  
  return (
    <>
      <p>
        Please read and accept the GSA IT General Rules of Behavior. This must be
        completed on an annual basis in order to use GSAFleet.gov. You can download 
        this PDF for your records.
      </p>
      <PDFViewer
        className="margin-y-3 border-1px" 
        source={source()}
      />
      <a
        href={source()}
        download={fileName}
        className="display-inline-block"
        data-testid="gsa-it-behavior-rules-anchor"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          label="Download"
          variant="outline"
          leftIcon={{
            name: "file_download"
          }}
          type="submit"
          data-testid="download-behavior-rules-btn"
        />
      </a>
    </>
  );
};

const Actions = ({ onConfirm }) => (
  <>
    <Button
      label="I accept"
      variant="primary"
      type="submit"
      data-testid="behavior-rules-accept-btn"
      onClick={onConfirm}
    />
  </>
);

Actions.propTypes = {
  onConfirm: PropTypes.func.isRequired,
};

const BehaviorRulesModal = () => {
  const { currentUser } = useCurrentUser();
  const [mustAcceptBehaviorRules, setMustAcceptBehaviorRules] = useState(false);
  const [hasAcceptingError, setHasAcceptingError] = useState(false);

  const [acceptBehaviorRules] = useMutation(ACCEPT_BEHAVIOR_RULES, {
    onError: () => {
      setMustAcceptBehaviorRules(true);
      setHasAcceptingError(true);
    },
    onCompleted: (data) => {
      if (data.acceptBehaviorRules) {
        setMustAcceptBehaviorRules(false);
        setHasAcceptingError(false);
      }
      if (localStorage.getItem('isSalesRedirect') === 'true') {
        localStorage.removeItem('isSalesRedirect');
        localStorage.removeItem('showBehaviorRulesModal');
        window.location.assign(`${window.AFP_CONFIG.appURLs.marketplace}/sales/landing`);
      }
    },
  });

  const handleConfirm = () => {
    acceptBehaviorRules();
  }

  const isBehaviorRulesExpired = (date) => {
    if (!date && date !== undefined) {
      return true;
    }
    const acceptedAt = new Date(date);
    const plusOneYear = new Date(acceptedAt.setFullYear(acceptedAt.getFullYear() + 1));
    return  plusOneYear < new Date();
  };

  useEffect(() => {
    if (currentUser) {
      const { behaviorRulesAcceptedAt } = currentUser;
      localStorage.setItem('showBehaviorRulesModal', isBehaviorRulesExpired(behaviorRulesAcceptedAt));
      setMustAcceptBehaviorRules(isBehaviorRulesExpired(behaviorRulesAcceptedAt));
    }
  }, [currentUser]);

  const ConfirmModal = connectModal(() => (
    <Modal
      hideClose
      variant="large"
      title={<Title />}
      actions={
        <Actions
          onConfirm={handleConfirm}
        />
      }
    >
      {hasAcceptingError && 
        <InternalError
          id="feedback-accept-behavior-rules-failure-message"
        />}

      <Content />
    </Modal>
  ));

  return <ConfirmModal isOpen={mustAcceptBehaviorRules} />;
};

export default BehaviorRulesModal;
