import { Button, TextInput, Alert } from '@gsa/afp-component-library';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  useCurrentUser,
  FleetTechnicalSupportContact,
} from '@gsa/afp-shared-ui-utils';
import AgreementModal from './agreement-modal';
import GettingStartedModal from './getting-started';
import LoginRedirectFeedback from './login-redirect-feedback';
import './public-home.scss';
import { isFeatureEnabled } from '../../utilities/feature-toggle';

const PublicPage = () => {
  const history = useHistory();
  const auth = useCurrentUser();
  const createAccountRef = useRef();
  const loginExternalRef = useRef();
  const loginGSARef = useRef();
  const [agreementCaller, setAgreementCaller] = useState(null);
  const [onConfirmAgreement, setOnConfirmAgreement] = useState(null);
  const [onGettingStarted, setonGettingStarted] = useState(null);
  const [loginRedirecting, setLoginRedirecting] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const isVehicleSalesEnabled = isFeatureEnabled('public-vehicle-sales');
  const [siteAlert, setSiteAlert] = useState(true);

  const onCloseAgreement = () => {
    setOnConfirmAgreement(null);
    if (agreementCaller) {
      agreementCaller.current.focus();
    }
    setAgreementCaller(null);
  };

  const onCloseModal = () => {
    setonGettingStarted(false);
  };

  const handleEsc = useCallback(
    (evt) => {
      if (evt.keyCode === 27) {
        onCloseAgreement();
        onCloseModal()
      }
    },
    [agreementCaller],
  );

  const handleOverlayClick = useCallback(
    (evt) => {
      if (evt.target.classList.contains('afp-modal-overlay')) {
        onCloseAgreement();
        onCloseModal()
      }
    },
    [agreementCaller],
  );

  const onCloseSiteAlert = () => {
    setSiteAlert(false);
  };

  /* TODO update modal with the ability to close when press Escape key
   * or click outside the modal (overlay)
   */

  useEffect(() => {
    if (agreementCaller) {
      const modalOverlay =
        document.getElementsByClassName('afp-modal-overlay')[0];
      modalOverlay.addEventListener('click', handleOverlayClick, false);
      document.addEventListener('keydown', handleEsc, false);
      return () => {
        document.removeEventListener('keydown', handleEsc, false);
        modalOverlay.removeEventListener('click', handleOverlayClick, false);
      };
    }
    return undefined;
  }, [agreementCaller]);

  /**
   * to create an account
   * @param {*} e
   */
  const handleCreateAccount = (e) => {
    e.preventDefault();
    setOnConfirmAgreement(() => async () => {
      history.push('/signup');
    });
    setAgreementCaller(createAccountRef);
  };

  /**
   * to login for External Users using Okta ( Vendors, Customer and Public Users)
   * @param {*}
   */
  const handleLoginExternal = (e) => {
    e.preventDefault();
    setOnConfirmAgreement(() => async () => {
      setOnConfirmAgreement(null);
      setLoginRedirecting(true);
      await auth.loginUserExternal();
    });
    setAgreementCaller(loginExternalRef);
  };

  /**
   * to login for GSA Employees using Secure Auth
   * @param {*} e
   */
  const handleLoginGSA = (e) => {
    e.preventDefault();
    setOnConfirmAgreement(() => async () => {
      setOnConfirmAgreement(null);
      setLoginRedirecting(true);
      await auth.loginUserGSA();
    });
    setAgreementCaller(loginGSARef);
  };

  const homePageBuyVehiclesText = (
    <>
      <p className="margin-y-0">
        <h3 className="apph-small margin-y-0">
          We sell sedans, vans, SUVs, trucks and more.
          <br />
        </h3>
        GSA Fleet Vehicle Sales, a division of the Federal Government, offers quality US Govt. owned cars, trucks, buses, and vans to the public at auction. Public auctions are held in-lane and online nationwide, approximately 40,000 cars sold every year.
        <br />
      </p>

      <Button
        label="Buy vehicles today!"
        data-testid="public-buy-vehicle-button"
        className="margin-top-2 tablet:width-viewport"
        type="button"
        variant="outline"
        onClick={() => {
          window.location.href = `${window.AFP_CONFIG.appURLs.marketplace}/sales/landing`;
        }}
      />
    </>
  );

  const homePageAnnouncementText = (
    <>
      <p>
        The <b>Federal Vehicle Standards</b> are
        {` GSA's classification system for
        commercially available vehicles, published annually to cover the current
        model year. They are intended to:`}
        <ul>
          <li>
            Establish minimum technical and quality specifications for vehicles
            and optional equipment;
          </li>
          <li>
            Ensure vehicles purchased by GSA are safe, durable, and economical;
            and
          </li>
          <li>Provide uniformity in the acquisition process.</li>
        </ul>
      </p>

      <Button
        label="View federal standards"
        data-testid="public-fvs-button"
        type="button"
        variant="outline"
        onClick={() => {
          window.location.href = `${window.AFP_CONFIG.appURLs.catalog}/public/fvs/vehicle-standards`;
        }}
      />

      <p>
        For general questions about{' '}
        <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>GSAFleet.gov</a>,
        join us for our Monthly Customer Q&A session, every Third Thursday of
        the month.&nbsp;
        <a
          href="https://gsa.zoomgov.com/webinar/register/WN_cNzd9c1AQjSpAnvKd18AgA"
          target="_blank"
          rel="noreferrer"
        >
          Register now
        </a>
        .
      </p>
    </>
  );

  const handleGettingStarted =()=>{
    setonGettingStarted(true)
  }

  const handleSearchGovSearch = () => {
    if (searchQuery) {
      window.open(
        `https://search.usa.gov/search?affiliate=gsafleet.gov&query=${searchQuery}&commit=Search`,
      );
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearchGovSearch();
    }
  };
  
  const alertText = (
    <>
      As part of our move we have upgraded our bidding platform and retired all bidder accounts associated with fleetautoauctions.gov.
      <br />
      <b>A new bidder account is required to participate in all GSA Fleet auctions, including live and online events.</b>
      <br />
      Contact <a href="mailto:autoauctions@gsa.gov">autoauctions@gsa.gov</a> with any questions.
      <br />
      <br />
      <Button
        label="Create a new bidder account"
        data-testid="public-fvs-button"
        type="button"
        variant="primary"
        onClick={() => {
          window.location.href = `${window.AFP_CONFIG.appURLs.marketplace}/sales/register`;
        }}
      />
    </>
  );


  
  return (
    <>
      <div>
        {siteAlert && (
          <Alert 
            type="info" 
            heading="GSA Fleet Internet Sales are now on GSAFleet.gov" 
            showClose 
            onClose={onCloseSiteAlert}
          >
            {alertText}
          </Alert>
        )}
      </div>
      <div className="grid-row flex-justify desktop:grid-gap-4">
        <div className="grid-col-12 desktop:grid-col-8">
          <h1 className="text-primary text-normal font-body-2xl">
            Welcome to GSA<b>Fleet</b>.gov
          </h1>
          {isVehicleSalesEnabled && (
          <>
            <h2 className="text-primary card-heading title-s-caps text-900">
              Looking to buy vehicles?
            </h2>

            <div className="grid-row">
              <div className="grid-col-12 desktop:grid-col-4 ">
                <img src="/buy-vehicles.jpg" alt="buy-vehicles" />
              </div>
              <div className="grid-col-12 desktop:grid-col-8 desktop:padding-left-2 ">
                <span className="text-normal">{homePageBuyVehiclesText}</span>
              </div>
            </div>
          </>
          )}

          <span className="font-body-lg padding-top-2">
            {homePageAnnouncementText}
          </span>

          <div className="divider padding-top-2 padding-bottom-2" />
          <h2 className="text-normal">Need help?</h2>
          <p>
            For step-by-step instructions on creating an account or logging in
            to GSAFleet.gov, please use the{' '}
            <a
              href={`${process.env.PUBLIC_URL}/GSAFleet_gov-Account-Creation-User-Guide-v2.pdf`}
              className="mobile:display-block"
              data-testid="gsa-user_management-guid"
              target="_blank"
              rel="noopener noreferrer"
            >
              Account Creation User Guide
            </a>{' '}
            located on our{' '}
            <a href="/help" target="_blank">
              Help
            </a>{' '}
            page.
          </p>

          <p>
            For further assistance, <FleetTechnicalSupportContact />.
          </p>

          <div
            className="grid-row flex-align-end margin-bottom-5"
            data-testid="searchgov"
          >
            <div className="tablet:grid-col-6">
              <TextInput
                label={<strong>Search GSAFleet.Gov</strong>}
                name="searchgov"
                id="searchgov"
                type="text"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                onKeyDown={(e) => {
                  handleKeyDown(e);
                }}
              />
            </div>
            <div className="tablet:grid-col-2">
              <Button
                label="Search"
                type="button"
                onClick={handleSearchGovSearch}
                className="tablet:margin-left-2 margin-top-1"
                variant="outline"
              />
            </div>
          </div>
        </div>
        <div className="grid-col-12 desktop:grid-col-4 padding-top-4">
          <div className="public-home-card-container">
            <h2>Get started</h2>
            <Button
              label="Create account"
              className="usa-button--fluid"
              data-testid="account-create-testid"
              type="button"
              variant="outline"
              onClick={handleGettingStarted}
              inputRef={createAccountRef}
            />
            <div className="divider padding-top-5 padding-bottom-2" />

            <h2>Log in</h2>
            <p>
              Government and federal agencies, vendors, or registered public auction bidders.
            </p>
            <Button
              label="Log in"
              className="usa-button--fluid"
              data-testid="fas-id-login-testid"
              type="button"
              variant="primary"
              onClick={handleLoginExternal}
              inputRef={loginExternalRef}
            />
            <Button
              label="GSA employees"
              className="usa-button--fluid employee-login"
              data-testid="gsa-email-login-testid"
              type="button"
              variant="unstyled"
              onClick={handleLoginGSA}
              inputRef={loginGSARef}
            />
          </div>
        </div>
      </div>
      {typeof onConfirmAgreement === 'function' && (
        <AgreementModal
          onConfirm={onConfirmAgreement}
          onClose={onCloseAgreement}
        />
      )}
      {onGettingStarted && (
        <GettingStartedModal
          onClose={onCloseModal}
          handleCreateAccount={handleCreateAccount}
          handleLoginGSA={handleLoginGSA}
        />
      )}
      {loginRedirecting && <LoginRedirectFeedback />}
    </>
  );
};

export default PublicPage;