import React from 'react';
import { FleetTechnicalSupportContact } from '@gsa/afp-shared-ui-utils';
import { SummaryBox, Button } from '@gsa/afp-component-library';
import { Link } from 'react-router-dom';
import { isCustomerUser, isGSAEmployee } from '../../utilities/user';

/* eslint-disable import/prefer-default-export */
export const HELP_SIDENAV_MAPPING = {
  help: {
    title: 'Need a little help?',
    description: {
      leftSide: (
        <>
          <p>
            Explore the collection of resources below for step-by-step user
            guides, training videos, and links to additional resources and
            information.
          </p>
          <p>
            For further assistance, <FleetTechnicalSupportContact />
          </p>
          <Link to="/gsa-fleet-how-to" className="title-s-caps">
            GSAFleet.gov
          </Link>
          <p>
            This page contains step-by-step guides and short, helpful videos for
            common processes.
          </p>

          <Link to="/gsa-fleet-overview" className="title-s-caps">
            Overview of GSA Fleet
          </Link>
          <p>
            Take a look at GSA Fleet&apos;s suite of offerings, including
            vehicle purchasing, vehicle leasing, and short-term rentals.
          </p>

          <Link to="/resource-center" className="title-s-caps">
            Resource center
          </Link>
          <p>
            GSA Fleet offers multiple training options. Get up to speed on
            current and past offerings.
          </p>
              
          <Link to="/glove-box" className="title-s-caps">
            Glove Box
          </Link>
          <p>
            GSA Fleet leased vehicles have a Fleet Service Card for fuel,
            maintenance, and repair services. The card is assigned to a specific
            vehicle. Please call <b>1 (866) 400-0411</b> for support.
          </p>

          <p>
            1. Press 1 for maintenance, battery, tires & roadside assistance{' '}
            <br />
            2. Press 2 for accidents, body & glass damage <br />
            3. Press 4 for a Fleet Service Card acceptance problem
          </p>
        </>
      ),
    },
  },
  'gsa-fleet-how-to': {
    title: 'GSAFleet.gov',
    description: {
      leftSide: (
        <>
          <p>
            Learn how to use GSAFleet.gov so you can spend less time navigating
            our system and more time accomplishing your agency mission. We have
            step-by-step user guides and short videos for common processes to
            help get you started.
          </p>
          <p>
            For further assistance, <FleetTechnicalSupportContact />
          </p>
        </>
      ),
      rightSide: (
        <div className="padding-right-1">
          <SummaryBox.SummaryBox>
            <SummaryBox.SummaryHeader>
              Account Creation
            </SummaryBox.SummaryHeader>
            <SummaryBox.SummaryBody>
              <p>Learn how to create an account and log in to GSAFleet.gov.</p>
            </SummaryBox.SummaryBody>
            <SummaryBox.SummaryFooter>
              <a
                href={`${process.env.PUBLIC_URL}/GSAFleet_gov-Account-Creation-User-Guide-v2.pdf`}
                target="blank"
              >
                <Button
                  leftIcon={{ name: 'document_file', type: 'custom' }}
                  label="View User Guide"
                  className="usa-button width-full margin-top-2"
                />
              </a>
            </SummaryBox.SummaryFooter>
          </SummaryBox.SummaryBox>
        </div>
      ),
    },
    cards: [
      {
        header: 'User management',
        body: 'Fleet administrators: Learn how to manage user accounts.',
        actions: [
          {
            url: 'https://vimeo.com/790868891/d68d2b1b4d',
            label: 'Watch Video',
            variant: 'outline',
            leftIcon: '',
          },
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet_gov-User-Management-User-Guide-v3.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Vehicle registration',
        body: 'Learn how to register your agency-owned vehicle.',
        actions: [
          {
            url: 'https://vimeo.com/790867826/04eb0707d2',
            label: 'Watch Video',
            variant: 'outline',
            leftIcon: '',
          },
          {
            url: `${process.env.PUBLIC_URL}/Vehicle-Registration-User-Guide-Sept2022-v2.pdf?v=1.1`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Vehicle inventory management',
        body: 'Learn how to manage your agency-owned inventory & FAST reporting.',
        actions: [
          {
            url: 'https://vimeo.com/790866710/38d9ffb2d3',
            label: 'Watch Video',
            variant: 'outline',
            leftIcon: '',
          },
          {
            url: `${process.env.PUBLIC_URL}/Vehicle-Inventory-Management-User-Guide.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Fleet leasing card replacement',
        body: 'Learn how to order a replacement fleet card for a GSA leased vehicle.',
        actions: [
          {
            url: 'https://player.vimeo.com/video/803072721?h=b8f4300947',
            label: 'Watch Video',
            variant: 'outline',
            leftIcon: '',
          },
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet.gov-Fleet-Leasing-Card-Replacement-User-Guide.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Dispatch and reservation (Dispatcher)',
        body: 'Learn how to create and manage motor pools, users, and reservations.',
        actions: [
          {
            url: 'https://player.vimeo.com/video/812855949?h=0b937726f1',
            label: 'Watch Video',
            variant: 'outline',
            leftIcon: '',
          },
          {
            url: `${process.env.PUBLIC_URL}/Dispatch-and-Reservation-Dispatcher-User-Guide.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Dispatch and reservation (Member)',
        body: 'Learn how to create and manage vehicle reservations.',
        actions: [
          {
            url: 'https://player.vimeo.com/video/812875850?h=b8cdd8fee6',
            label: 'Watch Video',
            variant: 'outline',
            leftIcon: '',
          },
          {
            url: `${process.env.PUBLIC_URL}/Dispatch-and-Reservation-Driver-User-Guide.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Wallet',
        body: 'Finance officers: Learn how to manage accounting information for GSA Fleet offerings.',
        actions: [
          {
            url: 'https://vimeo.com/gsavisualcommunications/review/842952720/8eeb36cb17',
            label: 'Watch Video',
            variant: 'outline',
            leftIcon: '',
          },
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet_gov-Wallet-User-Guide-v3.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Modification requests',
        body: 'Learn how to request a modification for a GSA leased vehicle.',
        actions: [
          {
            url: 'https://vimeo.com/gsavisualcommunications/review/875339253/c139b084aa',
            label: 'Watch Video',
            variant: 'outline',
            leftIcon: '',
          },
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet_gov-Customer-Modification-Requests-User-Guide.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Scheduled reports',
        body: 'Learn how to create and manage scheduled reports.',
        actions: [
          {
            url: 'https://vimeo.com/gsavisualcommunications/review/885699144/1c990efd14',
            label: 'Watch Video',
            variant: 'outline',
            leftIcon: '',
          },
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet_gov-Reports-User-Guide-v2.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Recalls',
        body: 'Learn how to view recalls at a vehicle and campaign level.',
        actions: [
          {
            url: 'https://vimeo.com/gsavisualcommunications/review/906419962/b66d65abd5',
            label: 'Watch Video',
            variant: 'outline',
            leftIcon: '',
          },
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet_gov-Recalls-User-Guide.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Vendor management',
        body: 'Learn how to register, manage user accounts, and manage your company profile.',
        props: {
          type: 'multipleVideos',
          label: 'Watch Video',
          items: [
            {
              url: 'https://vimeo.com/gsavisualcommunications/review/917182581/ee0ed376fd',
              label: 'Vendor Registration',
              variant: 'outline',
              leftIcon: '',
            },
            {
              url: 'https://vimeo.com/gsavisualcommunications/review/912701616/81f8798fe3',
              label: 'Vehicle Supplier Profile',
              variant: 'outline',
              leftIcon: '',
            },
          ],
        },
        actions: [
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet_gov-Vendor-Management-User-Guide.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Federal vehicle standards commenting',
        body: 'Learn how to create comments on Documentation, Standard Items, and Equipment Codes in the Federal Vehicle Standards.',
        actions: [
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet_gov-Federal_Vehicle_Standards_Commenting.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Preventative maintenance',
        body: 'Learn how to view and manage Preventative Maintenance for your GSA leased vehicle. Please note: PM Express was renamed to PM Reporting after video production.',
        actions: [
          {
            url: 'https://vimeo.com/gsavisualcommunications/review/939372241/ac44cdcf06',
            label: 'Watch Video',
            variant: 'outline',
            leftIcon: '',
          },
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet.gov-Preventative-Maintenance-User-Guide.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Mileage reporting',
        body: 'Learn how to view and report monthly mileage for your GSA leased vehicle.',
        actions: [
          {
            url: 'https://vimeo.com/gsavisualcommunications/review/1001308850/400c8d51a9',
            label: 'Watch Video',
            variant: 'outline',
            leftIcon: '',
          },
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet.gov-Mileage-Reporting-User-Guide.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Customer driven data',
        body: 'Learn how to manage and edit your Customer Driven Data for a GSA leased vehicle.',
        actions: [
          {
            url: 'https://vimeo.com/gsavisualcommunications/review/862109279/913f0917e1',
            label: 'Watch Video',
            variant: 'outline',
            leftIcon: '',
          },
        ],
      },
      {
        header: 'Purchasing a Vehicle',
        body: 'Learn how to purchase a vehicle for your agency on GSAFleet.gov',
        actions: [
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet_gov-Purchasing-User-Guide-v3.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Managing orders',
        body: 'Vehicle Suppliers: Learn how to view vehicle orders, provide order vehicle statuses and browse your vehicle offerings in GSAFleet.gov.',
        actions: [
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet_gov-Order-Management-User-Guide-v3.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Bid management',
        body: 'Vehicle Suppliers: Learn how to offer products in response to a GSA Fleet issued solicitation.',
        actions: [
          {
            url: 'https://vimeo.com/gsavisualcommunications/review/973134277/4ff6404fda',
            label: 'Watch Video',
            variant: 'outline',
            leftIcon: '',
          },
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet_gov-Bid Management-User-Guide-v3.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Marshalling',
        body: 'Vehicle Marshallers: Learn how to manage marshalling tasks in GSAFleet.gov, such as loading a vehicle, completing an eVAF, and scheduling appointments as a Marshaller.',
        props: {
          type: 'multipleVideos',
          label: 'Watch Video',
          items: [
            {
              url: 'https://vimeo.com/gsavisualcommunications/review/1009242663/01aab48d0b',
              label: 'Marshalling',
              variant: 'outline',
              leftIcon: '',
            },
            {
              url: 'https://vimeo.com/gsavisualcommunications/review/1012102025/347768a2f7',
              label: 'Vehicle load and delivery',
              variant: 'outline',
              leftIcon: '',
            },
          ],
        },
        actions: [
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet_gov-External_Marshalling_User_Guide.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Vehicle sales',
        body: 'Auction House Users: Learn how to download pre-sale files and upload invoice and proceeds files in GSAFleet.gov.',
        actions: [
          {
            url: 'https://vimeo.com/gsavisualcommunications/review/1009242663/01aab48d0b',
            label: 'Watch Video',
            variant: 'outline',
            leftIcon: '',
          },
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet_gov-Vehicle_Sales_Vendor_Guide.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Request a leased vehicle',
        body: 'Learn how to request a replacement leased vehicle and navigate the approval process.',
        actions: [
          {
            url: 'https://vimeo.com/gsavisualcommunications/review/1046532459/283d673b0e',
            label: 'Watch Video',
            variant: 'outline',
            leftIcon: '',
          },
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet_gov-Request_Replacement_Vehicle_User_Guide.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Contract management',
        body: 'Vehicle Suppliers: Learn how to view and make updates to your contracts in GSAFleet.gov.',
        actions: [
          {
            url: 'https://vimeo.com/gsavisualcommunications/review/1047908180/8b4a70e749',
            label: 'Watch Video',
            variant: 'outline',
            leftIcon: '',
          },
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet_gov-Contract-Management-for-Vehicle-Suppliers-User-Guide.pdf`,
            label: 'View User Guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
    ],
  },
  'gsa-fleet-overview': {
    title: 'Overview of GSA Fleet',
    description: {
      leftSide: (
        <p>
          GSA Fleet provides safe, reliable, low-cost vehicle solutions for
          federal agencies and{' '}
          <a
            href="https://www.gsa.gov/policy-regulations/policy/acquisition-policy/eligibility-determinations"
            target="blank"
          >
            eligible entities
          </a>
          . GSA Fleet&apos;s suite of offerings include vehicle purchasing,
          vehicle leasing, and short-term rentals.
        </p>
      ),
    },
    cards: [
      {
        header: 'Vehicle leasing',
        body: 'GSA Fleet Leasing is an end-to-end fleet management solution that helps you reduce costs. This government-wide shared service saves taxpayer dollars by handling the overall management of its fleet vehicles - allowing agencies to focus resources on their core mission.',
        actions: [
          {
            url: 'https://www.gsa.gov/buy-through-us/products-services/transportation-logistics-services/fleet-management/vehicle-leasing',
            label: 'Learn More',
            variant: '',
            leftIcon: {
              name: 'launch',
            },
          },
        ],
      },
      {
        header: 'Vehicle purchasing',
        body: 'GSA Fleet Purchasing manages the mandatory government-wide vehicle acquisition program. For more information, click the button below.',
        actions: [
          {
            url: 'https://www.gsa.gov/buy-through-us/products-services/transportation-logistics-services/fleet-management/vehicle-purchasing',
            label: 'Learn More',
            variant: '',
            leftIcon: {
              name: 'launch',
            },
          },
        ],
      },

      {
        header: 'Short-term rentals',
        body: 'GSA Fleet Short-Term Rentals offers vehicles and equipment for seasonal work, special events, surge requirements, and replacement of vehicles temporarily out of service.',
        actions: [
          {
            url: 'https://www.gsa.gov/buy-through-us/products-services/transportation-logistics-services/fleet-management/shortterm-rentals-str',
            label: 'Learn More',
            variant: '',
            leftIcon: {
              name: 'launch',
            },
          },
        ],
      },
    ],
  },
  'resource-center': {
    title: 'Resource center',
    columns: '6',
    description: {
      leftSide: (
        <p>
          GSA Fleet offers multiple training options. Get up to speed on current
          and past offerings below.
        </p>
      ),
    },
    cards: [
      {
        header: 'Desktop workshops',
        body: (
          <>
            <p>
              GSA Fleet offers Desktop Workshops on various topics throughout
              the year.
            </p>
          </>
        ),
        actions: [
          {
            url: 'https://www.gsa.gov/gsa-fleet-training',
            label: 'Learn More',
            variant: '',
            leftIcon: {
              name: 'launch',
            },
          },
        ],
      },
      {
        header: 'Federal fleet manager certification',
        body: (
          <>
            <p>
              The Federal Fleet Manager Certification Program (FFMCP) is
              comprehensive training for federal fleet management at all levels.
            </p>
          </>
        ),
        actions: [
          {
            url: 'https://www.gsa.gov/buy-through-us/products-services/transportation-logistics-services/fleet-management/federal-fleet-manager-certification-program',
            label: 'Learn More',
            variant: '',
            leftIcon: {
              name: 'launch',
            },
          },
        ],
      },
      {
        header: 'Defensive driving course',
        body: (
          <>
            <p>
              GSA Fleet offers a defensive driving course through Improv
              Learning to ensure that our government drivers are properly
              trained and we increase driver safety.
            </p>
            <p>
              To access the training as a leasing customer, click the button
              below.
            </p>
          </>
        ),
        actions: [
          {
            url: 'https://www.gsa.gov/buy-through-us/products-and-services/transportation-and-logistics-services/fleet-management/vehicle-leasing/safety-training',
            label: 'Learn More',
            variant: '',
            leftIcon: {
              name: 'launch',
            },
          },
        ],
      },
      {
        header: 'FedFleet conference',
        body: (
          <>
            <p>
              Every year, GSA Fleet hosts our annual FedFleet conference for
              customers to learn more about Federal fleet management and the
              resources GSA can provide to support their fleet programs.
            </p>
          </>
        ),
        actions: [
          {
            url: 'https://www.gsa.gov/buy-through-us/products-and-services/transportation-and-logistics-services/fleet-management/fedfleet-2024?_gl=1*18x2j0t*_ga*MTE0Nzg4MDc3Ni4xNzA3OTI4OTk3*_ga_HBYXWFP794*MTcwOTMxMDI0Mi4xMy4xLjE3MDkzMTAyNDYuMC4wLjA',
            label: 'Learn More',
            variant: '',
            leftIcon: {
              name: 'launch',
            },
          },
        ],
      },
      {
        header: 'Customer leasing guide',
        body: (
          <>
            <p>
              This guide provides GSA Fleet customers that lease from GSA with
              detailed information about policies and practices when leasing a
              GSA vehicle.
            </p>
          </>
        ),
        actions: [
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet_gov-Customer_Leasing_Guide.pdf`,
            label: 'Learn More',
            variant: '',
            leftIcon: {
              name: 'launch',
            },
          },
        ],
        rule: (currentUser) =>
          isCustomerUser(currentUser?.userType?.id) ||
          isGSAEmployee(currentUser?.userType?.id),
      },
      {
        header: 'Agency Incurred Expense (AIE) guide',
        body: (
          <>
            <p>
              This guide details the most common situations in which an Agency
              Incurred Expense (AIE) may occur.
            </p>
          </>
        ),
        actions: [
          {
            url: `${process.env.PUBLIC_URL}/GSAFleet_gov-AIE_Guide.pdf`,
            label: 'Learn More',
            variant: '',
            leftIcon: {
              name: 'launch',
            },
          },
        ],
        rule: (currentUser) =>
          isCustomerUser(currentUser?.userType?.id) ||
          isGSAEmployee(currentUser?.userType?.id),
      },
    ],
  },
  'glove-box': {
    title: 'Glove Box',
    description: {
      leftSide: (
        <p>
          This section contains all the information you as a driver need for
          your GSA vehicle, including information about fleet assistance and
          electric vehicle charging.
        </p>
      ),
      rightSide: (
        <div className="padding-right-1">
          <SummaryBox.SummaryBox>
            <SummaryBox.SummaryHeader>
              Fleet service card
            </SummaryBox.SummaryHeader>
            <SummaryBox.SummaryBody>
              <p>
                GSA Fleet leased vehicles card for fuel, maintenance, and repair
                services. Call <b>1 (866) 400-0411</b> for support or contact
                your Fleet Service Representative (FSR).
              </p>
              <a href="tel: 1-866=400-0411" target="blank">
                <Button
                  label="Call 1 (866) 400-0411"
                  className="usa-button width-full"
                />
              </a>
              <a
                href="https://www.gsa.gov/buy-through-us/products-services/transportation-logistics-services/fleet-management/vehicle-leasing/find-a-fleet-service-representative"
                target="blank"
              >
                <Button
                  label="Contact Fleet Service Representative (FSR)"
                  variant="outline"
                  className="usa-button width-full margin-top-2"
                />
              </a>
            </SummaryBox.SummaryBody>
          </SummaryBox.SummaryBox>
        </div>
      ),
    },
    cards: [
      {
        header: 'Guide to your GSA Fleet leased vehicle',
        body: 'Everything you need to know about the benefits and operations of your GSA Fleet vehicle.',
        actions: [
          {
            url: `${process.env.PUBLIC_URL}/Vehicle-Guide.pdf`,
            label: 'View user guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Charge at public stations',
        body: 'Learn how to charge your GSA Fleet electric vehicle at public charging stations.',
        actions: [
          {
            url: `${process.env.PUBLIC_URL}/Charge-Your-Electric-Vehicle-at-Public-Sites.pdf`,
            label: 'View user guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Assistance card',
        body: "Access the numbers for GSA Fleet's contact center in order to get on the road after an accident or find the nearest maintenance location.",
        actions: [
          {
            url: `${process.env.PUBLIC_URL}/Assistance-Card.pdf`,
            label: 'View user guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'Accident reporting kit',
        body: 'Access all the forms necessary to report your accident.',
        actions: [
          {
            url: `https://www.gsa.gov/forms-library/fleet-vehicle-accident-kit`,
            label: 'Learn more',
            variant: '',
            leftIcon: { name: 'launch' },
          },
        ],
      },
      {
        header: 'Tesla guides',
        body: 'Find all the information you need to operate your GSA Fleet leased Tesla.',
        actions: [
          {
            url: `${process.env.PUBLIC_URL}/GSA-Tesla-Quick-Guide.pdf`,
            label: 'View GSA Tesla quick guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
          {
            url: `${process.env.PUBLIC_URL}/GSA-Tesla-Guide.pdf`,
            label: 'View GSA Tesla guide',
            variant: 'outline',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
      {
        header: 'PI/Data clearing guide',
        body: 'Learn what personal information might be stored in your vehicle and how to remove it.',
        actions: [
          {
            url: `${process.env.PUBLIC_URL}/PI-Data-Clearing-Guide.pdf`,
            label: 'View user guide',
            variant: '',
            leftIcon: { name: 'document_file', type: 'custom' },
          },
        ],
      },
    ],
  },
};
export const getHelpNavItem = () => {
  return Object.entries(HELP_SIDENAV_MAPPING).map(([key, helpNavItem]) => {
    const title = key === 'help' ? 'Help' : helpNavItem.title;
    return { body: <Link to={key}>{title}</Link>, id: title };
  });
};
