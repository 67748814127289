import { USER_TYPE_ID } from './constants';

export const isUserTypeIdVendor = (userTypeId) =>
  userTypeId === USER_TYPE_ID.Vendor;

export const isUserTypeVendor = (userType) => isUserTypeIdVendor(userType?.id);

export const isUserVendor = (user) => isUserTypeVendor(user?.userType);

export const isGSAEmployee = (userTypeId) => userTypeId === USER_TYPE_ID.GSA;

export const isCustomerUser = (userTypeId) => userTypeId === USER_TYPE_ID.Customer;

export const isUserBidder = (userTypeId) => userTypeId === USER_TYPE_ID.Bidder;

export const isScopeLevel = (currentUser, targetScopeLevel, scopeValue, operation, subject) => {
  const abilities = currentUser?.abilities;

  if (!abilities) return false;

  return abilities?.some(([verb, obj, payload]) => {
    if (verb === operation && obj === subject) {
      if (!payload || !payload.serviceObjectScopes) return false;
      const { serviceObjectScopes } = payload;
      return (
        serviceObjectScopes &&
        serviceObjectScopes.$elemMatch &&
        serviceObjectScopes.$elemMatch[targetScopeLevel] === scopeValue
      );
    }
    return false;
  }) || false;
};
