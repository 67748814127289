import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import React from 'react';
import { canApproveOrDenyUser } from '../../../../users/authorization';
import { canEditUserStatus } from '../../../../users/components/modal/edit-user-status-modal';
import { Status } from '../../constants/user-details-constants';
import { useUserDetails } from '../providers';
import ApproveUserAccessButton from './approve-access-button';
import DenyUserAccessModal from './deny-access-modal';
import { EditUserStatusButton } from './edit-status-button';
import { EditUserProfileButton } from './edit-user-profile-button';
import './user-header.scss';
import UserName from './user-name';
import { canEditUserProfile } from '../../authorization';

// TODO use user status utility
const isPendingRole = (user) => user?.status?.id === Status.PENDING_ROLE;

const actions = (currentUserId) => {
  const { user } = useUserDetails();
  const ability = useAppAbility();
  const showApproveDeny = canApproveOrDenyUser(ability) && isPendingRole(user);
  const showEditStatus =
    user &&
    currentUserId &&
    canEditUserStatus(ability, user.status.id, currentUserId, user);
  const showEditProfile = user && canEditUserProfile(ability, user.status.id);

  return (
    <div className="flex-align-self-center">
      {showApproveDeny && (
        <>
          <DenyUserAccessModal />
          <ApproveUserAccessButton />
        </>
      )}
      {showEditStatus && <EditUserStatusButton />}
      {showEditProfile && <EditUserProfileButton userId={user.id} />}
    </div>
  );
};

const UserHeader = () => {
  const { currentUser } = useCurrentUser();
  const { user } = useUserDetails();
  const fullName = user ? user.fullName : '';
  return (
    <>
      <div className="desktop:display-flex desktop:flex-justify">
        <div className="desktop:display-flex">
          <UserName>{fullName}</UserName>
        </div>
        {actions(currentUser?.id)}
      </div>
    </>
  );
};

export default UserHeader;
