import React from 'react';
import PropTypes from 'prop-types';
import './getting-started.scss';
import { Modal, connectModal, Button } from '@gsa/afp-component-library';

const Title = () => {
  return <h2>Create Account</h2>;
};

const Actions = ({ onConfirm }) => (
  <>
    <Button
      label="I agree"
      variant="primary"
      type="submit"
      form="agreement-confirm-form"
      data-testid="agreement-confirm-btn"
      onClick={onConfirm}
    />
  </>
);

Actions.propTypes = {
  onConfirm: PropTypes.func.isRequired,
};

const GettingStartedModal = ({
  onClose,
  handleCreateAccount,
  handleLoginGSA,
}) => {
  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const CreateAccount = () => {
    return (
      <div className="create-account-modal">
        <div className="account-options">
          <div className="option public-auction">
            <div className="icon">
              <img
                src={`${process.env.PUBLIC_URL}/gavel-regular.png`}
                width="30"
                height="30"
                alt="public-auction"
              />
            </div>
            <h3>Public auction bidders</h3>
            <p>
              GSA pre-owned vehicle auctions are open to the public. Register to
              bid on online auctions or at live events. Experian credit check
              required.
            </p>
            <Button
              label="Register to bid"
              className="usa-button--fluid"
              data-testid="register-to-bid-testid"
              type="button"
              variant="outline"
              onClick={() => {
                window.location.href = `${window.AFP_CONFIG.appURLs.marketplace}/sales/register`;
              }}
            />
          </div>

          <div className="option government">
            <div className="icon">
              <img
                src={`${process.env.PUBLIC_URL}/Vector.png`}
                width="30"
                height="30"
                alt="Government"
              />
            </div>
            <h3>Government</h3>
            <p>
              Includes federal agencies and tribal nations; state, local, and
              territorial governments, and other eligible non-governmental
              organizations.
            </p>
            <Button
              label="Create government account"
              className="usa-button--fluid width-auto"
              data-testid="gov-account-create-testid"
              type="button"
              variant="outline"
              onClick={(e) => {
                handleCreateAccount(e);
                onClose();
              }}
            />
          </div>

          <div className="sidebar">
            <p>
              <strong>GSA Fleet vendors</strong> <br />
              Please reach out to your GSA Fleet Point of Contact or email{' '}
              <a href="mailto:fleetsystemsmodernization@gsa.gov">
                fleetsystemsmodernization@gsa.gov
              </a>{' '}
              to create an account.
            </p>
            <p>
              <strong>GSA employees</strong> <br />
              Use the{' '}
              <a
                href={`${process.env.PUBLIC_URL}/GSAFleet_gov-Account-Creation-User-Guide-v2.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                employee account guide
              </a>{' '}
              to create an account.
            </p>
            <p>
              <a
                href="#AlreadyHaveAnAccount"
                onClick={(e) => {
                  handleLoginGSA(e);
                  onClose();
                }}
              >
                Already have an account? Log in.
              </a>
            </p>
          </div>
        </div>
        <a
          href="#cancelButton"
          className="cancel-button"
          onClick={(e) => {
            e.preventDefault(); 
            handleClose(); 
          }}
        >
          {' '}
          Cancel
        </a>
      </div>
    );
  };
  const ConfirmModal = connectModal(() => (
    <Modal variant="extra-large" title={<Title />} onClose={handleClose}>
      <CreateAccount />
    </Modal>
  ));

  return <ConfirmModal isOpen />;
};

GettingStartedModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleCreateAccount: PropTypes.func.isRequired,
  handleLoginGSA: PropTypes.func.isRequired,
};

export default GettingStartedModal;
