import React, { useEffect, useState, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  Can,
  useAppAbility,
  useCurrentUser,
  useTitle,
} from '@gsa/afp-shared-ui-utils';
import {
  Button,
  DetailsTable,
  EmptyState,
  AFPTable,
  StatusBadge,
  Link,
  Spinner,
} from '@gsa/afp-component-library';
import { useUserDetails } from '../providers/user-details-provider';
import {
  formatPhoneNumber,
  capitalize,
} from '../../../../../utilities/StringUtils';
import { isFeatureEnabled } from '../../../../../utilities/feature-toggle';
import UserRoles from './user-roles';
import { Types, Status } from '../../constants/user-details-constants';
import AssignRolesModal from './assign-roles-modal';
import './user-information.scss';
import {
  GET_VENDORS_BY_FLEET_VENDOR_NUMBER,
  GET_BIDDER_PROFILE_INFO,
  GET_USER_ACTIVE_BIDS,
  GET_FLEET_MANAGEMENT_CENTER,
} from '../../../../../services/data-layer';
import { USER_TYPE_ID } from '../../../../../utilities/constants';
import { canEditBidderPassport } from '../../../../users/authorization';

// TODO use user status utility
const isUserActive = (user) => user?.status?.id === Status.ACTIVE;

export function bidTypeMap(bidType) {
  switch (bidType) {
    case 'F':
      return 'FLAT';
    case 'P':
      return 'PROXY';
    default:
      return '';
  }
}

const formattedBidAmt = (bidAmt) => {
  return bidAmt ? `$${parseInt(bidAmt, 10).toLocaleString('en-US')}` : '-';
};

const formattedBidDate = (bidDate) => {
  return new Date(bidDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'America/New_York',
    timeZoneName: 'short',
  });
};

const getBidStatus = (status) => {
  return status === 'A' ? 'Active' : status;
};

const userRoles = (targetUser) => {
  const [userActiveBids, setUserActiveBids] = useState(null);
  const marketplaceAppUrlBase = window?.AFP_CONFIG?.appURLs?.marketplace || '';
  const [fetchUserActiveBids, { loadingUserActiveBids, errorUserActiveBids }] =
    useLazyQuery(GET_USER_ACTIVE_BIDS, {
      context: {
        clientName: 'marketplace',
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setUserActiveBids(data.userBidHistory);
      },
      onError: () => {
        // Error is handled by the UI
      },
    });

  useEffect(() => {
    if (targetUser?.userType?.id === Types?.BIDDER) {
      fetchUserActiveBids({
        variables: {
          userBidHistoryInput: {
            orderBy: [
              {
                column: 'bidDate',
                order: 'DESC',
              },
            ],
            type: 'USER_BIDS',
            vehicleSaleId: '',
            bidderUserIds: [targetUser?.id],
            plugins: ['vehicle_details'],
            filter: {
              vehicleDetails: {
                saleStatus: 'Active',
              },
            },
          },
        },
      });
    }
  }, [fetchUserActiveBids, targetUser]);

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'VIN',
        sortable: false,
        /* eslint-disable react/prop-types */
        Cell: ({ row: { original } }) => {
          return original?.vehicleDetails?.vin && marketplaceAppUrlBase ? (
            /* eslint-disable jsx-a11y/anchor-is-valid  */
            <Link
              href={
                marketplaceAppUrlBase
                  ? `${marketplaceAppUrlBase}/sales/vehicle-details/${original.vehicleDetails.vin}`
                  : '#'
              }
            >
              <a target="_blank" rel="noopener noreferrer">
                {original?.vehicleDetails?.vin}
              </a>
            </Link>
          ) : (
            '-'
          );
        },
        minWidth: 80,
      },
      {
        Header: 'Bid Type',
        accessor: 'bidType',
        sortable: false,
        Cell: ({ row: { original } }) => {
          /* eslint-disable react/prop-types */
          return bidTypeMap(original?.bidType);
        },
      },
      {
        Header: 'Bid Amount',
        accessor: 'bidAmt',
        sortable: false,
        Cell: ({ row: { original } }) => {
          /* eslint-disable react/prop-types */
          return `${formattedBidAmt(original?.bidAmt)}`;
        },
      },
      {
        Header: 'Proxy Bid Amount',
        accessor: 'bidProxyAmt',
        sortable: false,
        Cell: ({ row: { original } }) => {
          /* eslint-disable react/prop-types */
          return `${formattedBidAmt(original?.bidProxyAmt)}`;
        },
      },
      {
        Header: 'Bid Date',
        accessor: 'bidDate',
        sortable: true,
        Cell: ({ row: { original } }) => {
          /* eslint-disable react/prop-types */
          return formattedBidDate(original?.bidDate);
        },
      },
      {
        Header: 'Bid Status',
        accessor: 'bidStatus',
        sortable: false,
        Cell: ({ row: { original } }) => {
          /* eslint-disable react/prop-types */
          const status = getBidStatus(original?.bidStatus);
          return (
            <StatusBadge
              variant={status === 'Active' ? 'Ready-Gray' : 'Urgent-Gray'}
            >
              {status}
            </StatusBadge>
          );
        },
      },
    ];

    return columnList;
  }, []);

  if (targetUser?.userType?.id === Types?.BIDDER) {
    if (loadingUserActiveBids) {
      return (
        <EmptyState
          hasBackground
          containerStyles="padding-y-5"
          topText={
            <div>
              <Spinner aria-busy="true" size="small" />
              <p>Loading.....</p>
            </div>
          }
        />
      );
    }

    if (errorUserActiveBids) {
      return (
        <EmptyState
          hasBackground
          containerStyles="padding-y-5"
          topText={<strong>Error fetching bids</strong>}
        />
      );
    }

    if (userActiveBids?.bids?.length === 0) {
      return (
        <EmptyState
          hasBackground
          containerStyles="padding-y-5"
          topText={<strong>This user has no bids</strong>}
        />
      );
    }

    return (
      <div className="bidders-table-container">
        <AFPTable
          fullWidth
          columns={columns}
          defaultSort="bidDate DESC"
          data={userActiveBids?.bids ?? []}
        />
      </div>
    );
  }

  // Check if the user is active
  if (!isUserActive(targetUser)) {
    return (
      <EmptyState
        hasBackground
        containerStyles="padding-y-5"
        topText={<strong>User must be active in order to assign roles</strong>}
      />
    );
  }

  // Default rendering when the user is not a BIDDER and is active
  return <UserRoles />;
};

const UserInformation = () => {
  const [vendor, setVendor] = useState(null);
  const [bidderProfile, setBidderProfile] = useState(null);
  const [fmcDetails, setFmcDetails] = useState(null);
  const [fieldOfficeDetails, setFieldOfficeDetails] = useState(null);
  const [, setVendorError] = useState(null);
  const [, setBidderProfileError] = useState(null);
  const {
    user,
    agencyName,
    bureauName,
    officeName,
    setFeedbackMessage,
    refetchUserDetails,
    refetchUserRoleScopes,
  } = useUserDetails();
  const { currentUser } = useCurrentUser();
  const [assigningRole, setAssigningRole] = useState(false);
  const ability = useAppAbility();

  const [getVendor] = useLazyQuery(GET_VENDORS_BY_FLEET_VENDOR_NUMBER, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: user?.userType?.id !== '2',
    onCompleted: (data) => {
      setVendor(data.getVendorDetailByFleetVendorNumber);
    },
    onError: () => {
      setVendorError('Not able to load vendors');
    },
  });

  const [getBidderProfile] = useLazyQuery(GET_BIDDER_PROFILE_INFO, {
    context: {
      clientName: 'marketplace',
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: user?.userType?.id !== '6',
    onCompleted: (data) => {
      setBidderProfile(data.getBidderProfileInfo);
    },
    onError: () => {
      setBidderProfileError('Not able to load Bidder profile');
    },
  });

  const [getFmcDetails] = useLazyQuery(GET_FLEET_MANAGEMENT_CENTER, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setFmcDetails(data.getFleetManagementCenter);

      // If user has a field office, find the matching field office from FMC response
      if (
        user?.internalAttrs?.fieldOffice?.id &&
        data.getFleetManagementCenter?.fieldOffices
      ) {
        const fieldOffice = data.getFleetManagementCenter.fieldOffices.find(
          (fo) => fo.id === user.internalAttrs.fieldOffice.id,
        );
        if (fieldOffice) {
          setFieldOfficeDetails(fieldOffice);
        }
      }
    },
    onError: () => {
      // Error is handled by the UI
    },
  });

  useEffect(() => {
    if (user?.vendorAttrs?.fleetVendorNumber) {
      getVendor({
        variables: {
          fleetVendorNumber: user?.vendorAttrs?.fleetVendorNumber,
        },
      });
    } else if (user?.userType?.id === '6') {
      getBidderProfile({
        variables: {
          userId: user?.id,
        },
      });
    } else if (
      user?.userType?.id === Types.GSA_EMPLOYEE &&
      user?.internalAttrs?.managementCenter?.id
    ) {
      getFmcDetails({
        variables: {
          fmcId: parseFloat(user.internalAttrs.managementCenter.id),
        },
      });
    }
  }, [user]);

  useTitle(capitalize(user?.fullName));
  const formatData = (...params) => {
    if (!params) {
      return null;
    }

    const filterParams = params?.filter((item) => item && item.trim().length);
    const formattedData = [];
    filterParams?.forEach((item) => {
      formattedData.push(item);
      formattedData.push(<br />);
    });
    return formattedData.length ? formattedData : null;
  };

  const formatCityStateZipcode = (city, state, zipcode) =>
    city?.concat(`, ${state}`).concat(` ${zipcode}`);

  const getAddressAndPhone = (userInfo) => {
    return [
      [
        'Address',
        formatData(
          userInfo?.addrline1,
          userInfo?.addrline2,
          userInfo?.addrline3,
          formatCityStateZipcode(
            userInfo?.city,
            userInfo?.state,
            userInfo?.postalCode,
          ),
        ),
      ],
      [
        'Phone',
        formatPhoneNumber(userInfo?.phoneNumber, userInfo?.phoneNumberExt),
      ],
    ];
  };

  const getWarrantInfo = (userInfo) => {
    if (
      !ability?.can('view', 'warrantLevel') ||
      userInfo.userType.id === USER_TYPE_ID.Customer
    )
      return [];
    return [
      [
        'Warrant Level',
        userInfo?.internalAttrs?.warrantLevel
          ? new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(userInfo?.internalAttrs?.warrantLevel)
          : 'N/A',
      ],
    ];
  };

  const getWorkInfo = (userInfo) => {
    if (!userInfo || !userInfo.userType) {
      return [];
    }
    const { userType, internalAttrs } = userInfo;

    let workInfo = [];

    if (userType.id === Types.CUSTOMER) {
      workInfo = [
        ...getAddressAndPhone(userInfo),
        ['Agency', agencyName],
        ['Bureau', bureauName],
        ['Office', officeName],
      ];
    }
    if (userType.id === Types.GSA_EMPLOYEE) {
      if (
        internalAttrs &&
        internalAttrs?.zone &&
        internalAttrs?.managementCenter
      ) {
        // Determine which address to use - field office if available, otherwise FMC
        const addressSource = fieldOfficeDetails || fmcDetails;

        // For GSA employees, only show address if we have FMC/FO data
        const addressInfo = addressSource
          ? [
              [
                'Address',
                formatData(
                  addressSource.address1,
                  addressSource.address2,
                  addressSource.address3,
                  formatCityStateZipcode(
                    addressSource.city,
                    addressSource.stateCode,
                    addressSource.zipCode,
                  ),
                ),
              ],
              [
                'Phone',
                addressSource.countryCode
                  ? `+${addressSource.countryCode} ${formatPhoneNumber(
                      userInfo?.phoneNumber,
                      userInfo?.phoneNumberExt,
                    )}`
                  : formatPhoneNumber(
                      userInfo?.phoneNumber,
                      userInfo?.phoneNumberExt,
                    ),
              ],
            ]
          : [
              [
                'Phone',
                formatPhoneNumber(
                  userInfo?.phoneNumber,
                  userInfo?.phoneNumberExt,
                ),
              ],
            ];

        workInfo = [
          ...addressInfo,
          ['Zone', internalAttrs?.zone?.name],
          ['Fleet Management Center', internalAttrs?.managementCenter?.name],
        ];
        if (internalAttrs?.fieldOffice) {
          workInfo.push(['Field Office', internalAttrs?.fieldOffice?.name]);
        }
      } else if (
        internalAttrs &&
        internalAttrs?.division &&
        internalAttrs?.branch
      ) {
        // For HQ GSA employees, show address and phone
        workInfo = [
          ...getAddressAndPhone(userInfo),
          ['Headquarter division', internalAttrs?.division?.name],
          ['Branch', internalAttrs?.branch?.name],
        ];
      } else {
        // For other GSA employees, show address and phone
        workInfo = [
          ...getAddressAndPhone(userInfo),
          ['Headquarter division', ''],
          ['Branch', ''],
        ];
      }
    } else if (userType.id === Types.VENDOR) {
      const contact = vendor?.vendorContacts?.find(
        (c) => c.contactType === 'VendorPhysicalContact',
      );
      workInfo = [
        ['Vendor name', vendor?.vendorName],
        [
          'Vendor types',
          vendor?.vendorTypes?.map((v) => v.commonCode?.name).join(', '),
        ],
        [
          'Address',
          formatData(
            contact?.address1,
            contact?.address2,
            formatCityStateZipcode(
              contact?.city,
              contact?.state?.stateCode,
              contact?.postalCode,
            ),
          ),
        ],
        [
          'Phone',
          formatPhoneNumber(userInfo?.phoneNumber, userInfo?.phoneNumberExt),
        ],
      ];
    }

    if (workInfo.length > 0 && isFeatureEnabled('accident-and-maintenance')) {
      const warrantInfo = getWarrantInfo(userInfo);
      workInfo.push(...warrantInfo);
    }

    return workInfo.filter(Boolean);
  };

  const formatFullName = (first, last) =>
    first?.concat(` ${last}`).trim().length
      ? capitalize(first?.concat(` ${last}`))
      : null;

  const mailto = (mail) => `mailto:${mail}`;

  const getSupervisorInfo = (userInfo) => {
    const { userType, supervisorUser } = userInfo;
    if (!userInfo || userType.id === Types.GSA_EMPLOYEE) {
      return [];
    }

    return [
      [
        'Name',
        formatFullName(
          supervisorUser?.firstName || '',
          supervisorUser?.lastName || '',
        ),
      ],
      [
        'Email',
        supervisorUser?.email ? (
          <a href={mailto(supervisorUser?.email)}>{supervisorUser?.email}</a>
        ) : null,
      ],
    ];
  };

  /**
   * Retrieves the registration information for a user.
   *
   * @param {Object} userInfo - The user information object.
   * @param {string} userInfo.userType.name - The type of the user.
   * @param {string} userInfo.createdAt - The date the user was created.
   * @param {string} [userInfo.lastLogin] - The date the user last logged in.
   * @param {string} userInfo.status.name - The status of the user.
   * @returns {Array<Array<string|JSX.Element>>} An array of arrays containing the registration information.
   */
  const getRegistrationInfo = (userInfo) => {
    const emdash = '\u2014';
    const dateOptions = {month: '2-digit', day: '2-digit', 'year': 'numeric'};

    if (!userInfo) {
      return [];
    }

    return [
      ['Type', userInfo?.userType?.name],
      ['Registration date', <span className='text-tabular'>{new Date(userInfo.createdAt).toLocaleDateString('en-US', dateOptions)}</span>],
      ['Last logged in', <span className='text-tabular'>{userInfo.lastLogin ? new Date(userInfo.lastLogin).toLocaleDateString('en-US', dateOptions) : emdash}</span>],
      ['Status', userInfo?.status?.name],
    ]
  }

  const getPersonalInfo = (userInfo) => {
    if (!userInfo) {
      return [];
    }
    const personalInfo = [
      ['Name', capitalize(userInfo?.fullName)],
      [
        'Email',
        user?.email ? (
          <a href={mailto(userInfo?.email)}>{userInfo?.email}</a>
        ) : null,
      ],
    ];

    const isBidder = userInfo?.userType?.id === Types.BIDDER;
    if (isBidder) {
      let companyName = '';
      let {
        physicalAddrLine1,
        physicalAddrLine2,
        physicalAddrLine3,
        physicalCity,
        physicalStateCode,
        physicalZipCode,
        mailingAddrLine1,
        mailingAddrLine2,
        mailingCity,
        mailingStateCode,
        mailingZipCode,
        countryCode,
      } = bidderProfile || {};

      // eslint-disable-next-line camelcase
      // eslint-disable-next-line prefer-const
      if (bidderProfile?.company_information) {
        // eslint-disable-next-line camelcase
        const companyInfo = bidderProfile?.company_information;

        if (companyInfo?.physicalAddrLine1) {
          physicalAddrLine1 = companyInfo.physicalAddrLine1;
        }

        if (companyInfo?.physicalAddrLine2) {
          physicalAddrLine2 = companyInfo.physicalAddrLine2;
        }

        if (companyInfo?.physicalAddrLine3) {
          physicalAddrLine3 = companyInfo.physicalAddrLine3;
        }

        if (companyInfo?.physicalCity) {
          physicalCity = companyInfo.physicalCity;
        }

        if (companyInfo?.physicalStateCode) {
          physicalStateCode = companyInfo.physicalStateCode;
        }

        if (companyInfo?.physicalZipCode) {
          physicalZipCode = companyInfo.physicalZipCode;
        }

        if (companyInfo?.mailingAddrLine1) {
          mailingAddrLine1 = companyInfo.mailingAddrLine1;
        }

        if (companyInfo?.mailingAddrLine2) {
          mailingAddrLine2 = companyInfo.mailingAddrLine2;
        }

        if (companyInfo?.mailingCity) {
          mailingCity = companyInfo.mailingCity;
        }

        if (companyInfo?.mailingStateCode) {
          mailingStateCode = companyInfo.mailingStateCode;
        }

        if (companyInfo?.mailingZipCode) {
          mailingZipCode = companyInfo.mailingZipCode;
        }

        if (companyInfo?.countryCode) {
          countryCode = companyInfo.countryCode;
        }

        if (companyInfo?.companyName) {
          companyName = companyInfo.companyName;
        }
      }

      const physicalAddress = [];
      physicalAddress.push(physicalAddrLine1 ? <>{physicalAddrLine1}</> : null);
      physicalAddress.push(
        physicalAddrLine2 ? <> {physicalAddrLine2}</> : null,
      );
      physicalAddress.push(
        physicalAddrLine3 ? (
          <>
            <br />
            {physicalAddrLine3}
          </>
        ) : null,
      );
      physicalAddress.push(
        physicalAddrLine1 || physicalAddrLine2 || physicalAddrLine3 ? (
          <br />
        ) : null,
      );
      physicalAddress.push(physicalCity ? <>{physicalCity},</> : null);
      physicalAddress.push(
        physicalStateCode ? <> {physicalStateCode}</> : null,
      );
      physicalAddress.push(physicalZipCode ? <> {physicalZipCode}</> : null);
      physicalAddress.push(
        physicalCity || physicalStateCode || physicalZipCode ? <br /> : null,
      );
      physicalAddress.push(countryCode ? <>{countryCode}</> : null);

      const mailingAddress = [];
      mailingAddress.push(mailingAddrLine1 ? <>{mailingAddrLine1}</> : null);
      mailingAddress.push(mailingAddrLine2 ? <> {mailingAddrLine2}</> : null);
      mailingAddress.push(mailingAddrLine1 || mailingAddrLine2 ? <br /> : null);
      mailingAddress.push(mailingCity ? <>{mailingCity},</> : null);
      mailingAddress.push(mailingStateCode ? <> {mailingStateCode}</> : null);
      mailingAddress.push(mailingZipCode ? <> {mailingZipCode}</> : null);
      mailingAddress.push(
        mailingCity || mailingStateCode || mailingZipCode ? <br /> : null,
      );
      mailingAddress.push(countryCode ? <>{countryCode}</> : null);

      if (companyName !== '' && companyName) {
        personalInfo.splice(1, 0, ['Company', companyName]);
      }

      personalInfo.push(['Physical Address', physicalAddress]);
      personalInfo.push(['Mailing Address', mailingAddress]);
      personalInfo.push([
        'Phone',
        userInfo?.phoneNumberExt
          ? `${userInfo?.phoneNumber} ext. ${userInfo?.phoneNumberExt}`
          : userInfo?.phoneNumber,
      ]);
    }

    const { userType, customerAttrs = {} } = userInfo;
    if (userType.id === Types.CUSTOMER) {
      personalInfo.push([
        'Non-federal employee working under contract',
        customerAttrs && customerAttrs?.isContractor ? 'Yes' : 'No',
      ]);
    }

    if (userType.id === Types.BIDDER && bidderProfile) {
      personalInfo.push(['Bidder number', bidderProfile.bidderNumber]);
      if (
        userInfo?.bidderProfile?.type === 'INTERNATIONAL_INDIVIDUAL' ||
        userInfo?.bidderProfile?.type === 'INTERNATIONAL_BUSINESS'
      ) {
        personalInfo.push([
          'Passport number',
          bidderProfile.decryptedPassportNumber,
        ]);
        personalInfo.push(['Country', bidderProfile.passportCountry]);
      }
      if (canEditBidderPassport(ability)) {
        // if (bidderProfile.decryptedSsn) {
        //   personalInfo.push(['SSN or EIN', bidderProfile.decryptedSsn]);
        // } else
        if (
          bidderProfile.company_information &&
          bidderProfile.company_information.decryptedEin
        ) {
          personalInfo.push([
            'SSN or EIN',
            bidderProfile.company_information.decryptedEin,
          ]);
        }
      }
    }
    return personalInfo;
  };

  const assignRoles = () => {
    if (!isUserActive(user) || user?.id === currentUser?.id) {
      return null;
    }

    const assignRoleFeedback = (feedback) => {
      setAssigningRole(false);
      if (feedback) {
        if (feedback.type === 'success') {
          refetchUserDetails();
          refetchUserRoleScopes();
        }
        setFeedbackMessage(feedback);
      }
    };

    return (
      <Can I="assign" a="UserRole">
        <Button
          label="Assign role"
          leftIcon={{
            name: 'add',
          }}
          variant="outline"
          onClick={() => {
            setAssigningRole(true);
          }}
          data-testid="assign-role-btn-testid"
        />
        {assigningRole && user && (
          <AssignRolesModal user={user} feedback={assignRoleFeedback} />
        )}
      </Can>
    );
  };

  return (
    <>
      <div className="user-information">
        <div className="margin-top-2">
          <div className="grid-row grid-gap-lg">
            <div className="tablet:grid-col-4">
              <h2 className="text-heavy text-primary font-sans-xs text-uppercase">
                Registration Details
              </h2>
              <div className="details-table margin-bottom-6 bg-gray-3 padding-3 radius-md">
                <DetailsTable data={getRegistrationInfo(user)} wrapText bordered />
                {
                  (bidderProfile?.experianStatus === 'ERROR' || bidderProfile?.experianStatus === 'REFER') && bidderProfile?.experianReason ?
                    <div className="border-top border-gray-10">
                      <p className="text-bold margin-top-1 margin-bottom-1">Reason [Experian]</p>
                      <p className="margin-0 padding-0">{bidderProfile.experianReason}</p>
                    </div> : null
                }
              </div>
              <h2 className="text-heavy text-primary font-sans-xs text-uppercase">
                Personal Information
              </h2>
              <div className="details-table margin-bottom-6 bg-gray-3 padding-3 radius-md">
                <DetailsTable data={getPersonalInfo(user)} wrapText bordered />
              </div>

              {user?.userType?.id !== Types.BIDDER && (
                <div className="customer-details">
                  <h2 className="text-heavy text-primary font-sans-xs text-uppercase">
                    Work Information
                  </h2>

                  <div className="details-table margin-bottom-6 bg-gray-3 padding-3 radius-md">
                    <DetailsTable data={[...getWorkInfo(user)]} bordered />
                  </div>

                  {(user?.userType?.id === Types.CUSTOMER ||
                    user?.userType?.id === Types.VENDOR) && (
                    <>
                      <h2 className="text-heavy text-primary font-sans-xs text-uppercase">
                        Manager Information
                      </h2>
                      <div className="details-table margin-bottom-6 bg-gray-3 padding-3 radius-md">
                        <DetailsTable
                          data={[...getSupervisorInfo(user)]}
                          bordered
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="tablet:grid-col-8">
              <div className="display-flex flex-row flex-justify">
                <div>
                  <h2 className="text-heavy text-primary font-sans-xs text-uppercase">
                    {user?.userType?.id === Types.BIDDER
                      ? 'BID ACTIVITY'
                      : 'Assigned Roles'}
                  </h2>
                </div>
                {assignRoles()}
              </div>
              {userRoles(user)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInformation;
